const displayName = "Yuri Cunha"; // Mostrar nome
const bannerDescription = "Database Administrator (dbA)"; // Descrição do banner
const mediumUrl =
	"https://api.allorigins.win/raw?url=https://medium.com/feed/@isYuriCunha"; // URL do feed do Medium
const mediumFilterKeyword = ""; // Palavra-chave para filtrar o feed do Medium
const email = "isyuricunha@duck.com"; // E-mail
const githubUrl = "https://github.com/isYuriCunha"; // URL do GitHub
const linkedInUrl = "https://www.linkedin.com/in/isYuriCunha/"; // URL do LinkedIn
const resumePdfTitle = "Yuri-Cunha.pdf"; // Título do PDF do currículo

const projects = [
	{
		title: "Unes page",
		description: "Clone da págine Unes",
		stack: ["HTML", "CSS"],
		imgName: "pc-robot.jpg",
		siteUrl: "http://unes.isyuricunha.com/",
		codeUrl: "https://github.com/isyuricunha/unes-page",
		altCodeUrl: "",
	},
	{
		title: "Auto GitHub follow e unfollow",
		description: "Seguir e deixar de seguir automaticamente usuários do GitHub",
		stack: ["Javascript"],
		imgName: "follow__unfollow.png",
		siteUrl: "https://github.com/isYuriCunha/Auto-Github-Follow-e-Unfollow",
		codeUrl: "https://github.com/isYuriCunha/Auto-Github-Follow-e-Unfollow",
		altCodeUrl: "",
	},
	{
		title: "SongDedi [Colaboração]",
		description:
			"Um serviço gratuito de dedicação de música adequado para casamentos, festas ou qualquer evento",
		stack: ["Vue.js", "Python Flask", "Firebase", "Google Analytics"],
		imgName: "songdedi.png",
		siteUrl: "https://songdedi-admin.herokuapp.com/",
		codeUrl: "https://github.com/huishun98/songdedi-admin",
		altCodeUrl: "https://github.com/huishun98/songdedi-requests",
	},
	{
		title: "Galera/Galeria de Projeto",
		description:
			"Um site semelhante à uma galeria, para colocar links diretos a seus projetos",
		stack: ["PHP", "CSS"],
		imgName: "galeria_de_projetos.jpg",
		siteUrl: "https://github.com/isYuriCunha/Auto-Github-Follow-e-Unfollow",
		codeUrl: "https://github.com/isYuriCunha/Auto-Github-Follow-e-Unfollow",
	},
];

export {
	projects,
	mediumUrl,
	mediumFilterKeyword,
	email,
	githubUrl,
	linkedInUrl,
	displayName,
	bannerDescription,
	resumePdfTitle,
};
